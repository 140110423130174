export enum CopayCodeRequestFailures {
  NoInsuranceCashCard = 'Patient has no insurance or is using a cash card',
  VimovoNotAccepted = 'Vimovo is not accepted',
  Qualification = 'Patient not qualified',
  GovernmentCheckAttestsNo = 'Govt check - attests no',
  GovernmentCheckDatabaseCheck = 'Govt check - failed on database check',
  NonAllowableRejectCode = 'Non-allowable reject code',
  DailyLimit = 'Daily limit reached',
  WeeklyLimit = 'Weekly limit reached',
  Cancelled = 'Cancelled',
  InsuranceAlone = 'Using Insurance Alone',
  DiscountCard = 'Discount Card check - failed on database check',
  PrimaryInsuranceResultCheckAttestsNo = 'Primary Insurance - attests no',
}
